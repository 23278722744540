
jQuery( document ).ready(function( $ ){

    /*
    |------------------------------------------------------
    | MOBILE NAVIGATION
    |------------------------------------------------------
    */
   
        $("nav.mobile-navigation").navigation({
            maxWidth: "99999px",
            label: false,
            type: 'push',
            gravity: 'right'
        });


    /*
    |------------------------------------------------------
    | SWIPER
    |------------------------------------------------------
    */

        if( $('.header-slider .swiper-slide').length > 1 ){
            var swiper = new Swiper('.header-swiper', {
                autoplay: 5000,
                loop: true
            }); 
        }
        



        $(".swiper-mini").each(function( index, element ) {
            
            //var slideCount = $( this ).find('.swiper-slide').length();

            //console.log( '---> ' + slideCount );
            //var slideCount = $("section.rotator .swiper-container .swiper-slide").length;

            var id1 = $(element).attr("id");
            id = '.swiper-mini-'+id1;

            nextButtonid = '.swiper-button-next-'+id1;
            prevButtonid = '.swiper-button-prev-'+id1;

            var slideCount = $( id + " .swiper-slide").length;
            if( slideCount > 1 ){

                var swiper = new Swiper(id, {
                    nextButton: nextButtonid,
                    prevButton: prevButtonid,
                    spaceBetween: 40,
                    loop: true
                });

            } else {

                $(id + " .swiper-button-next").hide();
                $(id + " .swiper-button-prev").hide();
            }

        });



});

        
